(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name serverNotFound.controller:ServerNotFoundCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.serverNotFound')
    .controller('ServerNotFoundCtrl', ServerNotFoundCtrl);

  function ServerNotFoundCtrl() {
    var vm = this;
    vm.ctrlName = 'ServerNotFoundCtrl';
  }
}());
